import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import '../assets/css/mycases.css'
import { toast } from 'react-toastify';
import axiosInstance from '../utils/axiosInstance';
import CaseDetails from './CaseDetails';
import { useNavigate } from 'react-router-dom';
import arrow from '../assets/images/navbar/Arrow - Right 1.png'
import edit from '../assets/images/Edit 2.png';
import deleteicon from '../assets/images/Trash can.png'
import '../assets/css/privileged.css'
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { ChatProvider } from '../ChatContext';
import { FaSpinner } from 'react-icons/fa';
import rightman from '../assets/images/mycases/right-man.svg'
import book from '../assets/images/mycases/book image.svg'
import backimg from '../assets/images/mycases/back-image.svg'
// import mobilebac from '../assets/images/mycases/'
import selected from '../assets/images/mycases/selected-for-arrow.svg'
import unselected from '../assets/images/mycases/unselected-for-arrow.svg'
import selecteddelete from '../assets/images/mycases/selected-delete.svg'
import unselecteddelete from '../assets/images/mycases/unselected-delete.svg'
import infoimage from '../assets/images/mycases/info-image.svg'
import search from '../assets/images/mycases/search-vector.svg'
import bottomimage from '../assets/images/mycases/bottom-add-case.svg'
import backbutton from '../assets/images/mycases/back-button.svg'
import mobilePriviledge from '../assets/images/mycases/mobilePriviledge.svg'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { FaTimes } from 'react-icons/fa';
import { useCaseContext } from '../CaseContext';



const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const PrivilegedContent = () => {

    const [cnr_number, setCnrNumber] = useState('')
    const [case_nickname, setCaseName] = useState('')
    const [cnrnumberActive, setCnrNumberActive] = useState('')
    const [casenameActive, setCaseNameActive] = useState('')
    const [addcase, setAddcase] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [cases, setCases] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoadingCases, setIsLoadingCases] = useState(true); // State to track loading of cases
    const [pollingCaseId, setPollingCaseId] = useState(null);
    const [showAddCase, setShowAddCase] = useState(false);
    const { selectedCase, setSelectedCase } = useCaseContext();

    console.log(cases);

    const access_token = sessionStorage.getItem('access_token');

    const storedUserInfo = sessionStorage.getItem("user-info");
    const parsedUserInfo = storedUserInfo ? JSON.parse(storedUserInfo) : null;


    useEffect(() => {
        fetchCases();
    }, []);

    const fetchCases = async () => {
        try {
            const response = await axiosInstance.get('/casedetail/cases/', {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                }
            });
            console.log(response.data);
            setCases(response.data);
        } catch (error) {
            console.error('Failed to fetch cases:', error);
        } finally {
            setIsLoadingCases(false);
        }
    }

    const confirmDeleteCase = (caseId) => {
        confirmAlert({
            title: 'Confirm To Delete',
            message: 'Are you sure you want to delete this case?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteCase(caseId)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };


    const DeleteCase = async (caseId) => {
        try {
            const response = await axiosInstance.delete(`/casedetail/cases/${caseId}/`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },

            });
            fetchCases();
            toast("Case Deleted Successfully");
        } catch (err) {
            console.log(err);
        }
    }

    const startPolling = (caseId) => {
        const interval = setInterval(async () => {
            try {
                const response = await axiosInstance.get(`casedetail/cnrbank/${caseId}/status/`, {
                    headers: { "Authorization": `Bearer ${access_token}` },
                });
                if (response.data.status === 'Completed_Extraction' || response.data.status ==='COMPLETED') {
                    setCases(prevCases => {
                        const existingIndex = prevCases.findIndex(c => c.id === response.data.id);
                        if (existingIndex > -1) {
                            const updatedCases = [...prevCases];
                            updatedCases[existingIndex] = response.data;
                            return updatedCases;
                        }
                        return [...prevCases, response.data];
                    });
                    clearInterval(interval);
                    setPollingCaseId(null);
                    // fetchCases(); 
                    toast.success('Case added successfully. Please check.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } catch (error) {
                console.error('Failed to fetch case status:', error);
            }
        }, 5000);
        return interval;
    };

    // useEffect(() => {
    //     let interval;
    //     if (pollingCaseId) {
    //         interval = startPolling(pollingCaseId);
    //     }
    //     return () => {
    //         if (interval) {
    //             clearInterval(interval);
    //         }
    //     };
    // }, [pollingCaseId]);

    const handlecase = async (e) => {
        e.preventDefault();
        if (!cnr_number.trim()) {
            toast.error('Please enter the valid cnr number.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!case_nickname.trim()) {
            toast.error('Please enter the case name.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setLoading(true);
        setMessage('Please Wait...');

        try {
            const response = await axiosInstance.post('casedetail/cases/', { cnr_number, case_nickname }, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                }
            });
            console.log(response.data);

            setCnrNumber('');
            fetchCases();
            setCaseName('');
            toast.success('Case added successfully. We are processing your case.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (err) {
            if (err.response && err.response.data) {
                const errorMessages = err.response.data;
                Object.keys(errorMessages).forEach(key => {
                    toast.error(`${errorMessages[key]}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            } else {
                toast.error('An unexpected error occurred. Please try again.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } finally {
            setLoading(false);
        }
    };



    if (isLoadingCases) {
        return (
            <div className="loading">
                <Navbar />
                <div style={{ height: "70vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <ClipLoader color="#ffffff" size={150} css={override} />
                    <p style={{ color: "#ffffff", marginTop: 20, fontSize: 20, textAlign: "center" }}>
                        Loading case details...<br />Please wait as we retrieve the latest information.
                    </p>
                </div>
            </div>
        );
    }

    const handleCaseDetails = async (cnr_number) => {
        console.log(cnr_number);
        try {
            const response = await axiosInstance.get(`casedetail/cnrbank/${cnr_number}/`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    "Content-Type": 'application/json'
                }
            })
            setSelectedCase(response.data);
            console.log(response.data)
        }
        catch (err) {
            console.log(err.message)
        }
    }

    console.log(selectedCase)

    if (selectedCase) {
        return (
            <CaseDetails/>
        );
    }
    // show if there are cases

    if (cases.length > 0) {
        const filteredCases = cases.filter(caseItem => caseItem.status === 'Completed_Extraction');

        return (
            <>
                <Navbar />
                <div className="main-case-priviledge">
                    <div className="cases-privileged-container">
                        <div className="add-case-section">
                            <img src={backbutton} alt="" className='back-button' onClick={() => { window.location.href = '/mycases'; }} />
                            <h2> <span style={{ color: '#5D54F1' }}>Add</span> Case</h2>

                            <div className="form-group">
                                <input
                                    type="text"
                                    id="cnr-number"
                                    placeholder="CNR Number*"
                                    name="cnr-number"
                                    value={cnr_number}
                                    onChange={(e) => setCnrNumber(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="add-case-para">
                                <p>
                                    *Case Number Record (CNR) is a unique 16 digit number given to all cases. For example, MHAU019999992015.
                                    <a href="https://caseminister.blob.core.windows.net/frontend/CNR Number.pdf
                                " target="_blank" rel="noopener noreferrer"> Click here for more information.</a>
                                </p>
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="case-name"
                                    placeholder="Case Name"
                                    name="case-name"
                                    value={case_nickname}
                                    onChange={(e) => setCaseName(e.target.value)}
                                    required
                                />
                            </div>
                            <button className="submit-btn" onClick={handlecase}>
                                {loading ? <div className="loader"></div> : 'Submit'}
                            </button>
                            <p className="info-text mt-5">
                                <span className="icon"><img style={{ height: '20px', width: '20px' }} src={infoimage} alt="" /></span> You can access a detailed version of your case, complete with summary and timeline, by clicking on the tab labeled with the CNR number and Case name.
                            </p>
                            <img src={bottomimage} alt="Book" className=".case-bottom-right-image" />
                        </div>
                        <div className="my-cases-section">
                            <div className='header-container'>
                                <h2><span style={{ color: '#5D54F1' }}>My</span> Cases</h2>
                                <div class="search-container">
                                    <input type="search" placeholder="Search Case" className="search-bar" />
                                    <span class="search-icon">
                                        <img src={search} alt="Search Icon" class="icon-img" />
                                    </span>
                                </div>
                            </div>
                            <div className="case-list">
                                {cases.map((caseItem) => (
                                    <div className="cases-item" style={{ display: 'flex' }} key={caseItem.id}>
                                        <div className='case-items' onClick={() => handleCaseDetails(caseItem.cnr_number)} style={{ display: 'flex', gap: '10px', width: '100%' }}>
                                            <div className='case-item'>
                                                <span>CNR NO: {caseItem.cnr_number}</span>
                                                <span>CASE NAME: {caseItem.case_nickname}</span>
                                            </div>
                                        </div>
                                        <div className="case-actions">
                                            <button className="action-btn" aria-label="View Case">
                                                <img src={unselected} alt="View" className='case-img' />
                                            </button>
                                            <button className="action-btn" aria-label="Delete Case" onClick={(e) => { e.stopPropagation(); confirmDeleteCase(caseItem.id); }}>
                                                <img src={unselecteddelete} alt="Delete" className='case-img' />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* mobile add case section */}
                            <div className="mobilePrivildgeContainer">
                                <img src={backimg} alt="" className="back-image" />
                                <div className="addcaseBut">
                                    <button onClick={() => setShowAddCase(true)}>Add Case</button>
                                </div>
                            </div>
                            <div className={`mobile-top-privilegde ${showAddCase ? 'show' : ''}`}>
                                <div className='mobile-privileged-main-side'>
                                    {/* <button className="close-button" onClick={() => setShowAddCase(false)}>X</button> */}
                                    <button className="close-button" onClick={() => setShowAddCase(false)}>
                                        <FaTimes />
                                    </button>  
                                    <h1> <span style={{ color: '#5D54F1' }}>Add </span>Case</h1>
                                    <div className="mobile-privileged-centered-box">
                                        <div class="mobile-privileged-container">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id="cnr-number"
                                                    placeholder="CNR Number*"
                                                    name="cnr-number"
                                                    value={cnr_number}
                                                    onChange={(e) => setCnrNumber(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <p class="info-text">
                                                <p>
                                                    *Case Number Record (CNR) is a unique 16 digit number given to all cases. For example, MHAU019999992015.
                                                    <a href="https://caseminister.blob.core.windows.net/frontend/CNR Number.pdf
                                                 " target="_blank" rel="noopener noreferrer"> Click here for more information.</a>
                                                </p>
                                            </p>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id="case-name"
                                                    placeholder="Case Name"
                                                    name="case-name"
                                                    value={case_nickname}
                                                    onChange={(e) => setCaseName(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className='mobile-footer-content'>
                                                <p class="mobile-instruction-text">
                                                    <img src={infoimage} alt="" /> Please provide your CNR No. and specify the name you'd like to use in the form, enabling swift access to recent tab information.
                                                </p>
                                                <div className="mobile-footer-image">
                                                    <button className="mobile-btn" onClick={handlecase}>
                                                        {loading ? <div className="loader"></div> : 'Submit'}
                                                    </button>
                                                    <img src={mobilePriviledge} alt="" className='mobileFooterImage' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    // show when there are no cases
    else {
        return (
            <>
                <Navbar />
                <div className='privileged-main-side'>
                    {/* <h1> <span style={{ color: '#5D54F1' }}>Please add your</span> case to proceed</h1> */}
                    <div className="img-text">
                        <img src={backbutton} alt="Back" style={{ cursor: 'pointer' }} onClick={() => { window.location.href = '/mycases'; }} />
                        <h1>
                            <span style={{ color: '#5D54F1' }}>Please add your</span> case to proceed
                        </h1>
                    </div>                    <div className="privileged-centered-box">
                        <div class="privileged-container">
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="cnr-number"
                                    placeholder="CNR Number*"
                                    name="cnr-number"
                                    value={cnr_number}
                                    onChange={(e) => setCnrNumber(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="case-name"
                                    placeholder="Case Name"
                                    name="case-name"
                                    value={case_nickname}
                                    onChange={(e) => setCaseName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='footer-content'>
                                <p>
                                    *Case Number Record (CNR) is a unique 16 digit number given to all cases. For example, MHAU019999992015.
                                    <a href="https://caseminister.blob.core.windows.net/frontend/CNR Number.pdf
                                    " target="_blank" rel="noopener noreferrer"> Click here for more information.</a>
                                </p>
                                <p class="instruction-text">
                                    <img src={infoimage} alt="" /> Please provide your CNR No. and specify the name you'd like to use in the form, enabling swift access to recent tab information.
                                </p>
                                <button className="btn" onClick={handlecase}>
                                    {loading ? <div className="loader"></div> : 'Add Case'}
                                </button>
                            </div>

                        </div>
                    </div>
                    <img src={rightman} alt="Bottom Left Image" class="bottom-left-image" />
                    <img src={bottomimage} alt="" className='bottom-right-image' />
                </div>
            </>
        );
    }
}

export default PrivilegedContent