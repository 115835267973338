import React from 'react';
import '../assets/css/CourtSelection.css';
import Navbar from './Navbar'
import rightcorner from '../assets/images/selectcourt/right-corner.png'
import leftcorner from '../assets/images/selectcourt/left-corner-illustration.png'
import center from '../assets/images/selectcourt/center-back.png'
import { useNavigate } from 'react-router-dom';
import mobileFlags from '../assets/images/mycases/mobileFlags.svg'
import NewNotificationBar from './NewNotificationBar'
import panjabhighcourt from '../assets/images/selectcourt/panjab_high_court.svg'
import dashboard from '../assets/images/profile/Vector.png'

function CourtSelection() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/privilege');
    }

    const handleAllhighcourt = () => {
        navigate('/all-high-court')
    }

    const handleSupremeCourt = () => {
        navigate('/supreme-court')
    }

    const handleDashboard=()=>{
        navigate('/user-dashboard')
    }
    return (
        <>
            <Navbar />
            <NewNotificationBar />
            <div className="container">
                <div className="header">
                    <h1><span style={{ color: '#5D54F1' }}>Select Your Court </span>to Get Case Details </h1>
                    <p>Download orders, view summaries, talk to your case in your language, and Analyze it in one step.</p>
                    <button className="court-dashboard-button" onClick={handleDashboard} >
                        <span className='slide1'>
                            <img src={dashboard} alt=""/>ALL CASES DASHBOARD
                        </span>
                    </button>
                </div>
                <div className="court-cards">
                    <div class="card-stack" onClick={handleClick}>
                        <div class="card background-card2">
                            <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/District-Court.svg"
                                alt="Background Image"
                                class="court-background-image"
                                loading="lazy"
                            />
                            <div class="court-background-overlay"></div>
                            <p>Indian District Courts</p>
                        </div>
                        <div class="card foreground-card2">
                            <div class="card-content">
                                <p>Indian District Courts</p>
                            </div>
                        </div>
                    </div>
                    <h2 className='mobile-heading'>Indian District Courts</h2>
                    <div class="card-stack main-supreme" onClick={handleSupremeCourt} >
                        <div class="card background-card2">
                            <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/1573657162supreme-court-of-india 1.svg"
                                alt="Background Image" class="court-background-image"
                                loading="lazy"
                            />
                            <div class="court-background-overlay"></div>
                            <p>Supreme Court of India</p>
                        </div>
                        <div class="card foreground-card2">
                            <div class="card-content">
                                <p>Supreme Court of India</p>
                            </div>
                        </div>
                    </div>
                    <h2 className='mobile-heading'>Supreme Court of India</h2>
                    <div class="card-stack" onClick={handleAllhighcourt}>
                        <div class="card background-card2">
                            <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/delhi_high_court.jpeg"
                                alt="Background Image"
                                class="court-background-image"
                                loading="lazy"
                            />
                            <div class="court-background-overlay"></div>
                            <p>Indian High Courts</p>
                        </div>
                        <div class="card foreground-card2">
                            <div class="card-content">
                                <p>Indian High Courts</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="high_court_section"> */}
                    {/* <div class="card-stack main-panjab" onClick={handlehighcourt}>
                            <div class="card background-card2">
                                <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/delhi_high_court.jpeg"
                                    alt="Background Image"c
                                    class="court-background-image"
                                    loading="lazy"
                                />
                                <div class="court-background-overlay"></div>
                                <p>High Court of Delhi</p>
                            </div>
                            <div class="card foreground-card2">
                                <div class="card-content">
                                    <p>High Court of Delhi</p>
                                </div>
                            </div>
                        </div>
                        <h2 className='mobile-heading'>High Court of Delhi</h2> */}
                    {/* <div class="card-stack main-panjab" onClick={handlePanjabHighCourt}>
                            <div class="card background-card2">
                                <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/panjab_high_court.svg"
                                    alt="Background Image"
                                    class="court-background-image"
                                    loading="lazy"
                                />
                                <div class="court-background-overlay"></div>
                                <p>High Court of <br /> Punjab & Haryana</p>
                            </div>
                            <div class="card foreground-card2">
                                <div class="card-content">
                                    <p>High Court Of <br /> Punjab & Haryana</p>
                                </div>
                            </div>
                        </div>
                        <h2 className='mobile-heading'>High Court of <br /> Panjab & Haryana</h2> */}
                    {/* </div> */}
                    <img src={mobileFlags} alt="" className='mobileFlags' />

                </div>
                <div className="court-footer">
                    <p style={{ marginTop: '50px' }}> <span style={{ color: '#5D54F1' }}> Disclaimer:</span> The content of this site is only for information purpose. Users are advised not to depend on the information and use it for official purpose. Neither the High Court of Delhi nor the National Informatics Centre, Ministry of Information Technology, Government of India is responsible for any damages arising in contract from the use of the content of this site.</p>
                </div>
            </div>

            <div className='court-bottom-image'>
                <img src={rightcorner} alt="" className='right-corner' />
                <img src={leftcorner} alt="" className='court-left-corner' />
                <img src={center} alt="" className='center-image' />
            </div>
        </>
    );
}

export default CourtSelection;
