import React, { useState } from 'react';
import '../assets/css/Newsignup.css';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import google from '../assets/images/signup/google_2702602.png'
import { jwtDecode as jwt_decode } from 'jwt-decode';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Authcontext';
import logo from '../assets/images/navbar/legal-tech-logo.svg';
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import leftimage from '../assets/images/signup/left-login-image.svg'
import loginvec from '../assets/images/signup/login-back-vector.svg'
import Navbar from './Navbar'
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const Newsignup = () => {
    const [email, setEmail] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [agreed, setAgreed] = useState(false);
    const { login } = useAuth();
    const navigate = useNavigate();
    const [passwordStrength, setPasswordStrength] = useState('');
    const [isLawyer, setIsLawyer] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (setter, setActive) => (event) => {
        setter(event.target.value);
        setActive(true);
    };
    const handleLawyerCheckboxChange = (event) => {
        setIsLawyer(event.target.checked);
    }

    const handleCheckBoxChange = () => {
        setAgreed(!agreed);
    };
    const handlePasswordChange = (e) => {
        const inputValue = e.target.value;
        setPassword(inputValue);
        const strength = calculatePasswordStrength(inputValue);
        setPasswordStrength(strength);
    };
   

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const validatePasswordwithRegx = (password) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+]{6,}$/;
        if (!regex.test(password)) {
            return false;
        }
        return true;
    };

    const calculatePasswordStrength = (password) => {
        if (password.length < 6) return 'weak';
        if (password.length >= 8 && /[0-9]/.test(password) && /[A-Za-z]/.test(password)) return 'strong';
        if (password.length >= 6 && /[0-9]/.test(password) && /[A-Za-z]/.test(password)) return 'medium';
        return 'weak';
    };

    const getPasswordStrengthColor = () => {
        switch (passwordStrength) {
            case 'weak': return 'red';
            case 'medium': return 'orange';
            case 'strong': return 'green';
            default: return '2px solid white';
        }
    };
    const validatePasswords = () => {

        if (password !== confirmPassword) {
            return false;
        }
        return true;
    };

    const handlesignup = async () => {

        if (!first_name.trim()) {
            toast.error('Please fil in the first name field.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (!last_name.trim()) {
            toast.error('Please fill in last name field.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (!email.includes("@")) {
            toast.error('Please enter the valid email address.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!password.trim()) {
            toast.error('Please fill in the password field.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!validatePasswordwithRegx(password)) {
            toast.error('Your password must contain at least one letter, one number, and be at least 6 characters long..', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!validatePasswords()) {
            toast.error('Password did not match.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!agreed) {
            toast.info('Please agree to the Terms of Service and Privacy Policy.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        setIsLoading(true);
        try {
            const result = await axiosInstance.post('api/signup/', { first_name, last_name, email, password, is_lawyer: isLawyer }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                withCredentials: true,
            });


            console.log('User info:', JSON.stringify(result.data));
            console.log('Access token:', result.data.access);
            const userId = result.data.user_id;
            console.log(userId);
            const intendedURL = sessionStorage.getItem('intendedURL');
            sessionStorage.removeItem('intendedURL');
            login();
            navigate("/wait-list");
            sessionStorage.setItem('user-info', JSON.stringify(result.data));
            sessionStorage.setItem('access_token', result.data.access);
            // navigate('/verify-otp', {state:{email, userId}});

        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with an error status code
                let errorMessage = '';
                if (error.response.data) {
                    if (error.response.data.email) {
                        toast.error(`${error.response.data.email[0]}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else if (error.response.data.password) {
                        toast.info(`${error.response.data.password[0]}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    else {
                    }
                }
                // setMessage(errorMessage);
            } else if (error.request) {
            } else {

            }
        }
        finally {
            setIsLoading(false);
        }
    };

    // signup with google
    const googleSuccess = (response) => {
        const credentialResponseDecode = jwt_decode(response.credential);
        const id_token = response.credential
        // Using axios for sending data to the server
        axiosInstance.post('api/google-login/', { id_token }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                // Handle the server response if needed
                console.log('Server response:', response);
                login();
                if (response.data.message === "Signed Up successful") {
                    navigate("/wait-list"); // Navigate to the wait list page
                }
                // sessionStorage.setItem('user-info', JSON.stringify(response.data));
                // sessionStorage.removeItem('intendedURL');
                // sessionStorage.setItem('access_token', response.data.access); // Make sure your backend is sending this data
                // const intendedURL = sessionStorage.getItem('intendedURL');
                navigate("/wait-list");
            })
            .catch(error => {
                console.error('Error:', error);

            });
    };

    const googleFailure = (error) => {

        toast.error(('Google sign in failed. Please try again.'), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <Navbar />
            <div className='signup-page'>
                <div className='upper-section'>
                    {/* <img src={logo} alt="" /> */}
                    <h3><span style={{ color: '#5D54F1' }}>Welcome to </span> Case Minister</h3>
                    {/* <p>Sign up today to start for free and be a part of the new legal revolution! We are here to guide you every step of the way.</p> */}
                </div>
                <div className="signup-centered-box">
                    <div className="mobile-upper-section">
                        <p>Sign up today to start for free and be a part of the new legal revolution!</p>
                    </div>
                    <div className="signup-main-container">
                        <div className="login-left">
                            <div className="main-signup-text">
                                <h3>Sign up today to get on the wait list for CaseMinister’s access.</h3>
                            </div>
                            <p>Get FREE access for 1 month after entering your details and be a part of the new legal revolution!</p>
                            <div class="box2">
                                <div class="center">
                                    <div class="dialog-2">
                                        <div style={{
                                            paddingLeft: '10px',
                                            fontFamily: 'Arial, sans-serif',
                                            position: 'relative',
                                            // backgroundColor: '#f5f5f5',
                                            borderRadius: '10px',
                                            padding: '20px'
                                        }}>
                                            <span style={{
                                                fontSize: '3em',
                                                fontWeight: 'bold',
                                                verticalAlign: 'top',
                                                position: 'absolute',
                                                top:'0',
                                                left: '10px',
                                            }}>“</span>
                                            <p>
                                                CaseMinister is pivotal in modernizing legal practice and ensuring the profession is equipped for future challenges.
                                            </p>
                                            <span style={{
                                                fontSize: '3em',
                                                fontWeight: 'bold',
                                                verticalAlign: 'bottom',
                                                position: 'absolute',
                                                right: '25%',
                                                top:'45%',

                                            }}>”</span>
                                            <br />
                                            <span style={{
                                                color: '#4a90e2',
                                                fontWeight: 'bold',
                                                display: 'block',
                                                textAlign: 'left',
                                                marginTop:'-20px'
                                            }}>
                                                - Bar Council Of Delhi
                                            </span>
                                        </div>
                                        <div class="right-point">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="login-right">
                            <div className="login-container">
                                <div className='signup-main-headings'>
                                    <h3 className='mt-1'>Create your Account</h3>
                                </div>
                                <GoogleLogin
                                    buttonText="Login with Google"
                                    onSuccess={googleSuccess}
                                    onError={googleFailure}
                                    render={renderProps => (
                                        <button
                                            onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center', // Center button text
                                                alignItems: 'center',
                                                width: '100%', // Set button width to 100% of its container
                                                maxWidth: '400px', // Optional: if you want to limit its maximum width
                                                height: '50px', // Match the height of your other buttons
                                                backgroundColor: '#4285F4', // Google Blue color
                                                color: 'white', // Text color
                                                border: 'none', // No border
                                                borderRadius: '25px', // Slight rounded corners
                                                padding: '0 25px', // Padding on the sides
                                                fontSize: '16px', // Font back
                                                cursor: 'pointer', // Cursor to pointer
                                                boxShadow: '0 2px 4px rgba(0,0,0,0.25)', // Subtle shadow
                                                margin: '10px 0', // Margin top and bottom for spacing
                                            }}
                                        >
                                            <img
                                                src={google}
                                                alt="Google Icon"
                                                style={{
                                                    marginRight: '12px',
                                                    height: '24px',
                                                    width: '24px'
                                                }}
                                            />
                                            Sign in With Google
                                        </button>
                                    )}
                                />
                            </div>
                            <div className="signup-or-login-container">
                                <span className="line"></span>
                                <span className="or-text">or</span>
                                <span className="line"></span>
                            </div>
                            <div class="signup-container">
                                <div class="signup-form">
                                    <div class="form-row">
                                        <div class="form-group">
                                            <label for="first-name">First Name</label>
                                            <input
                                                type="text"
                                                id="first-name"
                                                placeholder="First name"
                                                value={first_name}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="last-name">Last Name</label>
                                            <input
                                                type="text"
                                                id="last-name"
                                                placeholder="Last name"
                                                value={last_name}
                                                onChange={(e) => setLastName(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            placeholder="example@gmail.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />                            </div>
                                    <div className="form-group" style={{ position: 'relative' }}>
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            placeholder="Your Password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            style={{
                                                backgroundColor: 'white',
                                                color: 'black',
                                                borderColor: getPasswordStrengthColor(),
                                                boxShadow: getPasswordStrengthColor(),
                                                paddingRight: '40px' // to make space for the icon
                                            }}
                                            required
                                        />
                                        <span
                                            onClick={togglePasswordVisibility}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-15%)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <label for="confirm-password">Confirm Password</label>
                                        <input
                                            type="password"
                                            id="confirm-password"
                                            placeholder="Confirm Password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                        />                            </div>
                                    {/* <div class="form-options">
                                <label>
                                    <input type="checkbox" name="terms" 
                                        checked={agreed}
                                        onChange={handleCheckBoxChange}
                                    /> Before proceeding further, click to confirm that you have read Case Minister’s Terms of Services and Privacy Policies
                                </label>
                            </div> */}
                                    <div class="form-options">
                                        <label class="checkbox-container">
                                            <input type="checkbox" name="terms"
                                                checked={agreed}
                                                onChange={handleCheckBoxChange}
                                            />
                                            <span class="checkbox-custom"></span>
                                            <span class="checkbox-text">Before proceeding further, click to confirm that you have read Case Minister’s Terms of Services and Privacy Policies</span>
                                        </label>
                                    </div>
                                    <div class="form-footer">
                                        {/* <button type="submit" onClick={handlesignup}>Sign up</button> */}
                                        <button type="submit" onClick={handlesignup} disabled={isLoading}>
                                            {isLoading ? <div className="loader"></div> : "Sign up"}
                                        </button>
                                        <p>Already have an account? <a href="/login">Login here</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={leftimage} alt="Decorative Image" class="left-image" />
                </div>
                <img src={loginvec} alt="" className='login-back-vec' />
            </div>
        </>
    )
}

export default Newsignup