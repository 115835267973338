import { React, useEffect, useState } from 'react';
import '../assets/css/userdashborad.css';
import Navbar from './Navbar'
import profileicon from '../assets/images/navbar/profileIcon.svg';
import dashboardicon from '../assets/images/profile/dashboard-icon.png'
import search from '../assets/images/profile/dashboard-search-icon.svg'
import caseIllustration from '../assets/images/profile/case-illustration.png'
import notificationIcon from '../assets/images/profile/user-dashboard-notification.png'
import filterIcon from '../assets/images/profile/Filter-icon.png'
import viewIcon from '../assets/images/profile/viewIcon.png'
import editIcon from '../assets/images/profile/editIcon.png'
import deleteIcon from '../assets/images/profile/deleteIcon.png'
import axiosInstance from '../utils/axiosInstance';
import { useCaseContext } from '../CaseContext';
import SupremeCoutDetail from './SupremeCoutDetail';
import HighCourtCaseDetails from './HighCourtCaseDetails';
import Allhighcourtcasedetail from './Allhighcourtcasedetail';
import PanjabHarayanaCasedetail from './PanjabHarayanaCasedetail';
import CaseDetails from './CaseDetails';
import moreContentIcon from '../assets/images/profile/more-content.svg'

function UserDashboard() {
    const [viewMode, setViewMode] = useState('list');
    const [cases, setCases] = useState({
        supremeCourtCases: [],
        highCourtCases: [],
        allHighCourtCases: [],
        districtCases: [],
        punjabHighCourtCases: [],
    });
    const [filteredCases, setFilteredCases] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({
        courtType: '',
        status: '',
        dateRange: '',
    });

    const { selecteSupremeCourtCase, setSelecteSupremeCourtCase } = useCaseContext();
    const { selectedHighCourtCase, setSelectedHighCourtCase } = useCaseContext();
    const { selectAllHighCourt, setSelectAllHighCourt } = useCaseContext();
    const { selectPanjabCourtCase, setSelectPanjabCourtCase } = useCaseContext();
    const { selectedCase, setSelectedCase } = useCaseContext();

    const access_token = sessionStorage.getItem('access_token');

    useEffect(() => {
        fetchCases();
    }, []);

    useEffect(() => {
        applyFiltersAndSearch();
    }, [cases, searchTerm, filters]);

    const fetchCases = async () => {
        try {
            const response = await axiosInstance.get('dashboard/casedetails/', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`,
                },
            });

            setCases({
                supremeCourtCases: formatCases(response.data.supreme_court_cases, 'Supreme Court'),
                highCourtCases: formatCases(response.data.high_court_cases, 'High Court'),
                allHighCourtCases: formatCases(response.data.all_high_court_cases, 'All High Court'),
                districtCases: formatCases(response.data.district_cases, 'District Court'),
                punjabHighCourtCases: formatCases(response.data.punjab_highcourt_cases, 'Punjab High Court'),
            });
        } catch (err) {
            console.error('Error fetching cases:', err);
        }
    };

    const formatCases = (cases, courtType) => {
        return (cases || []).map(caseData => ({
            ...caseData,
            court: courtType,
        }));
    };
    const applyFiltersAndSearch = () => {
        let filtered = [...Object.values(cases).flat()];

        // Apply court type filter
        if (filters.courtType) {
            filtered = filtered.filter(caseItem => caseItem.court === filters.courtType);
        }

        // Apply status filter
        if (filters.status) {
            filtered = filtered.filter(caseItem => {
                const status = caseItem.supremecourtcase?.case_status ||
                    caseItem.allhighcourtcase?.case_status ||
                    caseItem.cnrbank?.case_status;
                return status === filters.status;
            });
        }

        // Apply date range filter (assuming you have nextHearingDate in your case objects)
        if (filters.dateRange) {
            const today = new Date();
            const filterDate = new Date(today.getTime() - filters.dateRange * 24 * 60 * 60 * 1000);
            filtered = filtered.filter(caseItem => {
                const nextHearingDate = new Date(caseItem.nextHearingDate);
                return nextHearingDate >= filterDate;
            });
        }

        // Apply search
        if (searchTerm) {
            filtered = filtered.filter(caseItem => {
                const caseNo = caseItem.caseno ? caseItem.caseno.toString().toLowerCase() : '';
                const cnrNumber = caseItem.cnr_number ? caseItem.cnr_number.toString().toLowerCase() : '';
                const caseType = caseItem.case_type ? caseItem.case_type.toString().toLowerCase() : '';
                const court = caseItem.court.toLowerCase();

                return caseNo.includes(searchTerm.toLowerCase()) ||
                    cnrNumber.includes(searchTerm.toLowerCase()) ||
                    caseType.includes(searchTerm.toLowerCase()) ||
                    court.includes(searchTerm.toLowerCase());
            });
        }

        setFilteredCases(filtered);
    };

    

    if (selecteSupremeCourtCase) {
        return <SupremeCoutDetail />;
    }

    if (selectedHighCourtCase) {
        return <HighCourtCaseDetails />;
    }

    if (selectAllHighCourt) {
        return <Allhighcourtcasedetail />;
    }

    if (selectPanjabCourtCase) {
        return <PanjabHarayanaCasedetail />;
    }
    if (selectedCase) {
        return <CaseDetails />;
    }

    return (
        <>
            <Navbar />
            <div className="app">
                <div className='dashboard-main-container'>
                    <Header viewMode={viewMode} setViewMode={setViewMode} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                    <FilterBar filters={filters} setFilters={setFilters} />
                    <div className={viewMode === 'list' ? 'case-list1' : 'case-cards'}>
                        {filteredCases.map((caseData, index) => (
                            <CaseCard key={index} caseData={caseData} viewMode={viewMode} />
                        ))}
                        {filteredCases.length === 0 && <p>No cases found.</p>}
                    </div>
                </div>
            </div>
        </>
    );
}

function Header({ viewMode, setViewMode, searchTerm, setSearchTerm }) {
    const handleViewChange = (event) => {
        setViewMode(event.target.value);
    };

    const storedUserInfo = sessionStorage.getItem("user-info");

    // Parse the stored user info if it exists
    const parsedUserInfo = storedUserInfo ? JSON.parse(storedUserInfo) : null;

    if (parsedUserInfo && Array.isArray(parsedUserInfo) && parsedUserInfo[0]?.user) {
        console.log(parsedUserInfo[0].user.first_name); // Access first name from the first object in the array
        const username = parsedUserInfo[0].user.first_name; // Assign first name to username
    } else {
        console.log("User info not found or invalid format");
    }

;

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className="dashboard-header">
            <div className="search-bar1">
                <div className="dashboard-text-content">
                    <h6>Welcome</h6>
                    <h1>{parsedUserInfo[0].user.first_name} { parsedUserInfo[0].user.last_name}</h1>
                </div>
                <div className="dashboard-search-container">
                    <span className="dashboard-searc-icon">
                        <img src={search} alt="Search Icon" className="icon-img" />
                    </span>
                    <input
                        type="search"
                        placeholder="Search cases by case number, court, or status"
                        className="search-bar2"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    {/* <button className='dashboard-search'>
                        Search
                    </button> */}
                    <select
                        className="view-toggle"
                        value={viewMode}
                        onChange={handleViewChange}
                    >
                        <option value="list">List View</option>
                        <option value="cards">Card View</option>
                    </select>
                    <img src={notificationIcon} alt="" className='notificationIcon' />
                </div>
            </div>
        </div>
    );
}

function FilterBar({ filters, setFilters }) {
    const handleFilterChange = (event) => {
        setFilters({ ...filters, [event.target.name]: event.target.value });
    };

    return (
        <div className="filter-bar">
            <img src={filterIcon} alt="" />
            <select name="courtType" value={filters.courtType} onChange={handleFilterChange}>
                <option value="">Court Type</option>
                <option value="Supreme Court">Supreme Court</option>
                <option value="High Court">High Court</option>
                <option value="District Court">District Court</option>
                <option value="All High Court">All High Court</option>
                <option value="Punjab High Court">Punjab High Court</option>
            </select>
            <select name="status" value={filters.status} onChange={handleFilterChange}>
                <option value="">Status</option>
                <option value="Pending">Pending</option>
                <option value="Closed">DISPOSED</option>
                <option value="Ongoing">Ongoing</option>
            </select>
            <select name="dateRange" value={filters.dateRange} onChange={handleFilterChange}>
                <option value="">Date Range</option>
                <option value="7">Last 7 days</option>
                <option value="30">Last 30 days</option>
                <option value="90">Last 90 days</option>
            </select>
        </div>
    );
}


function CaseCard({ caseData, viewMode, onView, onEdit, onDelete }) {
    const access_token = sessionStorage.getItem('access_token');
    const { selecteSupremeCourtCase, setSelecteSupremeCourtCase } = useCaseContext();
    const { selectedHighCourtCase, setSelectedHighCourtCase } = useCaseContext();
    const { selectAllHighCourt, setSelectAllHighCourt } = useCaseContext();
    const { selectPanjabCourtCase, setSelectPanjabCourtCase } = useCaseContext();
    const {selectedCase, setSelectedCase} = useCaseContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleView = () => {
        onView(caseData);
        setIsMenuOpen(false);  // Close the menu after clicking
    };

    const handleEdit = () => {
        onEdit(caseData);
        setIsMenuOpen(false);  // Close the menu after clicking
    };

    const handleDelete = () => {
        onDelete(caseData);
        setIsMenuOpen(false);  // Close the menu after clicking
    };

    const handleSupremeCourtCaseDetails = async (case_number) => {
        try {
            const response = await axiosInstance.get(`sccourt/supremecourt/${case_number}/`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    "Content-Type": 'application/json'
                }
            });
            console.log(response.data);
            setSelecteSupremeCourtCase(response.data);
        } catch (err) {
            console.log(err.message);
        }
    }
    const HighCourtCaseDetail = async (case_number) => {
        try {
            const response = await axiosInstance.get(`highcourt/hcbank/${case_number}/ `, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    "Content-Type": 'application/json'
                }
            });
            console.log(response.data);
            setSelectedHighCourtCase(response.data);
        } catch (err) {
            console.log(err.message);
        }
    }
    const AllHighCourtCaseDetail = async (case_number) => {
        try {
            const response = await axiosInstance.get(`allhighcourt/hcbank/${case_number}/`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    "Content-Type": 'application/json'
                }
            });
            console.log(response.data);
            setSelectAllHighCourt(response.data);
        } catch (err) {
            console.log(err.message);
        }
    }
    const DistrictCourtCaseDetail = async (cnr_number) => {
        try {
            const response = await axiosInstance.get(`casedetail/cnrbank/${cnr_number}/`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    "Content-Type": 'application/json'
                }
            });
            console.log(response.data);
            setSelectedCase(response.data);
        } catch (err) {
            console.log(err.message);
        }
    }

    const PunjabHighCourtCaseDetail = async (case_number) => {
        try {
            const response = await axiosInstance.get(`highcourt/punjabhcbank/${case_number}/`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    "Content-Type": 'application/json'
                }
            });
            console.log(response.data);
            setSelectPanjabCourtCase(response.data);
        } catch (err) {
            console.log(err.message);
        }
    }
    return (
        <>
            {viewMode === "cards" ? (
                <div className="cases-container">
                    <div className="case-card">
                        <div className="case-card-section">
                            {caseData.court === 'Supreme Court' && (
                                <>
                                    <div className="case-card-right-section">
                                        <h3>Case Number</h3>
                                        <p>Case Title</p>
                                        <h3>Court</h3>
                                        {/* <p>Next date of hearing</p>
                                        <p>Previous date of hearing</p> */}
                                        <p>Status</p>
                                    </div>
                                    <div className="case-card-left-section">
                                        <h3>{caseData.caseno}</h3>
                                        <p>{caseData.case_type}</p>
                                        <h3>Supreme Court</h3>
                                        {/* <p>12th December, 2024</p>
                                        <p>4th June, 2024</p> */}
                                        <p>{caseData.supremecourtcase.case_status}</p>
                                    </div>
                                    <div className="actions">
                                        <button onClick={() => handleSupremeCourtCaseDetails(caseData.supremecourtcase.id)}><img src={viewIcon} alt="" />View</button>
                                        {/* <button><img src={editIcon} alt="" />Edit</button> */}
                                        <button><img src={deleteIcon} alt="" />Delete</button>
                                    </div>

                                </>
                            )}
                            {caseData.court === 'High Court' && (
                                <>
                                    <div className="case-card-right-section">
                                        <h3>Case Number</h3>
                                        <p>Case Title</p>
                                        <h3>Court</h3>
                                        {/* <p>Next date of hearing</p>
                                        <p>Previous date of hearing</p> */}
                                        <p>Year</p>
                                    </div>
                                    <div className="case-card-left-section">
                                        <h3>{caseData.caseno}</h3>
                                        <p>{caseData.case_type}</p>
                                        <h3>Supreme Court</h3>
                                        {/* <p>12th December, 2024</p>
                                        <p>4th June, 2024</p> */}
                                        <p>{caseData.year}</p>
                                    </div>
                                    <div className="actions">
                                        <button onClick={() => HighCourtCaseDetail(caseData.highcourtcase)}><img src={viewIcon} alt="" />View</button>
                                        {/* <button><img src={editIcon} alt="" />Edit</button> */}
                                        <button><img src={deleteIcon} alt="" />Delete</button>
                                    </div>

                                </>
                            )}
                            {caseData.court === 'All High Court' && (
                                <>
                                    <div className="case-card-right-section">
                                        <h3>Case Number</h3>
                                        <p>Case Title</p>
                                        <h3>Court</h3>
                                        {/* <p>Next date of hearing</p>
                                        <p>Previous date of hearing</p> */}
                                        <p>Status</p>
                                    </div>
                                    <div className="case-card-left-section">
                                        <h3>{caseData.caseno}</h3>
                                        <p>{caseData.case_type}</p>
                                        <h3>{caseData.bench}</h3>
                                        {/* <p>12th December, 2024</p>
                                        <p>4th June, 2024</p> */}
                                        <p>{caseData.allhighcourtcase.case_status}</p>
                                    </div>
                                    <div className="actions">
                                        <button onClick={() => AllHighCourtCaseDetail(caseData.allhighcourtcase.id)} ><img src={viewIcon} alt="" />View</button>
                                        {/* <button><img src={editIcon} alt="" />Edit</button> */}
                                        <button><img src={deleteIcon} alt="" />Delete</button>
                                    </div>

                                </>
                            )}
                            {caseData.court === 'District Court' && (
                                <>
                                    <div className="case-card-right-section">
                                        <h3>Case Number</h3>
                                        <p>Case Title</p>
                                        <h3>Court</h3>
                                        {/* <p>Next date of hearing</p>
                                        <p>Previous date of hearing</p> */}
                                        <p>Status</p>
                                    </div>
                                    <div className="case-card-left-section">
                                        <h3>{caseData.cnr_number}</h3>
                                        <p>{caseData.cnrbank.case_type}</p>
                                        <h3>District Court</h3>
                                        {/* <p>12th December, 2024</p>
                                        <p>4th June, 2024</p> */}
                                        <p>{caseData.cnrbank.case_status}</p>
                                    </div>
                                    <div className="actions">
                                        <button onClick={() => DistrictCourtCaseDetail(caseData.cnr_number)} ><img src={viewIcon} alt="" />View</button>
                                        {/* <button><img src={editIcon} alt="" />Edit</button> */}
                                        <button><img src={deleteIcon} alt="" />Delete</button>
                                    </div>

                                </>
                            )}
                            {caseData.court === 'Punjab High Court' && (
                                <>
                                    <div className="case-card-right-section">
                                        <h3>Case Number</h3>
                                        <p>Case Title</p>
                                        <h3>Court</h3>
                                        {/* <p>Next date of hearing</p>
                                        <p>Previous date of hearing</p> */}
                                        <p>Year</p>
                                    </div>
                                    <div className="case-card-left-section">
                                        <h3>{caseData.caseno}</h3>
                                        <p>{caseData.case_type}</p>
                                        <h3>Supreme Court</h3>
                                        {/* <p>12th December, 2024</p>
                                        <p>4th June, 2024</p> */}
                                        <p>{caseData.year}</p>
                                    </div>
                                    <div className="actions">
                                        <button onClick={() => PunjabHighCourtCaseDetail(caseData.punjabhighcourtcase.id)} ><img src={viewIcon} alt="" />View</button>
                                        {/* <button><img src={editIcon} alt="" />Edit</button> */}
                                        <button><img src={deleteIcon} alt="" />Delete</button>
                                    </div>

                                </>
                            )}
                            <img src={caseIllustration} alt="" className='dashboard-right-illustration' />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="case-list1">
                    <div className="case-item1">
                        <div className="case-details1">
                            <div className="case-info1  ">
                                {caseData.court === 'Supreme Court' && (
                                    <>
                                        <div>
                                            <h4>Case Number</h4>
                                            <p>{caseData.caseno}</p>
                                        </div>
                                        <div>
                                            <h4>Case Title</h4>
                                            <p>{caseData.case_type}</p>
                                        </div>
                                        <div>
                                            <h4>Court</h4>
                                            <p>Supreme Court</p>
                                        </div>
                                        {/* <div>
                                            <h4>Next Date Of Hearing</h4>
                                            <p>14 jan 2023</p>
                                        </div>
                                        <div>
                                            <h4>Previous Date Of Hearing</h4>
                                            <p>13 jan 2025</p>
                                        </div> */}
                                        <div>
                                            <h4>Status</h4>
                                            <p>{caseData.supremecourtcase.case_status}</p>
                                        </div>
                                            <div className="dropdown-container">
                                                <button className="dropdown-button" onClick={toggleMenu}>
                                                    <img src={moreContentIcon} alt="More actions" />
                                                </button>
                                                {isMenuOpen && (
                                                    <div className="dropdown-menu">
                                                        <button onClick={() => handleSupremeCourtCaseDetails(caseData.supremecourtcase.id)}>
                                                            <span role="img" aria-label="View">👁️</span> View
                                                        </button>
                                                        {/* <button onClick={handleEdit}>
                                                            <span role="img" aria-label="Edit">✏️</span> Edit
                                                        </button> */}
                                                        <button onClick={handleDelete}>
                                                            <span role="img" aria-label="Delete">🗑️</span> Delete
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                    </>
                                )}
                                {caseData.court === 'High Court' && (
                                    <>
                                        <div>
                                            <h4>Case Number</h4>
                                            <p>{caseData.caseno}</p>
                                        </div>
                                        <div>
                                            <h4>Case Title</h4>
                                            <p>{caseData.case_type}</p>
                                        </div>
                                        <div>
                                            <h4>Court</h4>
                                            <p>High Court</p>
                                        </div>
                                        {/* <div>
                                            <h4>Next Date Of Hearing</h4>
                                            <p>14 jan 2023</p>
                                        </div>
                                        <div>
                                            <h4>Previous Date Of Hearing</h4>
                                            <p>13 jan 2023</p>
                                        </div> */}
                                        <div>
                                            <h4>Year</h4>
                                            <p>{caseData.year}</p>
                                        </div>
                                            <div className="dropdown-container">
                                                <button className="dropdown-button" onClick={toggleMenu}>
                                                    <img src={moreContentIcon} alt="More actions" />
                                                </button>
                                                {isMenuOpen && (
                                                    <div className="dropdown-menu">
                                                        <button onClick={() => HighCourtCaseDetail(caseData.highcourtcase)}>
                                                            <span role="img" aria-label="View">👁️</span> View
                                                        </button>
                                                        {/* <button onClick={handleEdit}>
                                                            <span role="img" aria-label="Edit">✏️</span> Edit
                                                        </button> */}
                                                        <button onClick={handleDelete}>
                                                            <span role="img" aria-label="Delete">🗑️</span> Delete
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                    </>
                                )}
                                {caseData.court === 'All High Court' && (
                                    <>
                                        <div>
                                            <h4>Case Number</h4>
                                            <p>{caseData.caseno}</p>
                                        </div>
                                        <div>
                                            <h4>Case Title</h4>
                                            <p>{caseData.case_type}</p>
                                        </div>
                                        <div>
                                            <h4>Court</h4>
                                            <p>{caseData.bench}</p>
                                        </div>
                                        {/* <div>
                                            <h4>Next Date Of Hearing</h4>
                                            <p>14 jan 2025</p>
                                        </div>
                                        <div>
                                            <h4>Previous Date Of Hearing</h4>
                                            <p>13 jan 2023</p>
                                        </div> */}
                                        <div>
                                            <h4>Status</h4>
                                            <p>{caseData.allhighcourtcase.case_status}</p>
                                        </div>
                                            <div className="dropdown-container">
                                                <button className="dropdown-button" onClick={toggleMenu}>
                                                    <img src={moreContentIcon} alt="More actions" />
                                                </button>
                                                {isMenuOpen && (
                                                    <div className="dropdown-menu">
                                                        <button onClick={() => AllHighCourtCaseDetail(caseData.allhighcourtcase.id)} >
                                                            <span role="img" aria-label="View">👁️</span> View
                                                        </button>
                                                        {/* <button onClick={handleEdit}>
                                                            <span role="img" aria-label="Edit">✏️</span> Edit
                                                        </button> */}
                                                        <button onClick={handleDelete}>
                                                            <span role="img" aria-label="Delete">🗑️</span> Delete
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                    </>
                                )}
                                {caseData.court === 'District Court' && (
                                    <>
                                        <div>
                                            <h4>Case Number</h4>
                                            <p>{caseData.cnr_number}</p>
                                        </div>
                                        <div>
                                            <h4>Case Title</h4>
                                            <p>{caseData.cnrbank.case_type}</p>
                                        </div>
                                        <div>
                                            <h4>Court</h4>
                                            <p>District Court</p>
                                        </div>
                                        {/* <div>
                                            <h4>Next Date Of Hearing</h4>
                                            <p>{caseData.nextHearingDate}</p>
                                        </div>
                                        <div>
                                            <h4>Previous Date Of Hearing</h4>
                                            <p>{caseData.prevHearingDate}</p>
                                        </div> */}
                                        <div>
                                            <h4>Status</h4>
                                            <p>{caseData.cnrbank.case_status}</p>
                                        </div>
                                            <div className="dropdown-container">
                                                <button className="dropdown-button" onClick={toggleMenu}>
                                                    <img src={moreContentIcon} alt="More actions" />
                                                </button>
                                                {isMenuOpen && (
                                                    <div className="dropdown-menu">
                                                        <button onClick={() => DistrictCourtCaseDetail(caseData.cnr_number)}>
                                                            <span role="img" aria-label="View">👁️</span> View
                                                        </button>
                                                        {/* <button onClick={handleEdit}>
                                                            <span role="img" aria-label="Edit">✏️</span> Edit
                                                        </button> */}
                                                        <button onClick={handleDelete}>
                                                            <span role="img" aria-label="Delete">🗑️</span> Delete
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                    </>
                                )}
                                {caseData.court === 'Punjab High Court' && (
                                    <>
                                        <div>
                                            <h4>Case Number</h4>
                                            <p>{caseData.caseno}</p>
                                        </div>
                                        <div>
                                            <h4>Case Title</h4>
                                            <p>{caseData.case_type}</p>
                                        </div>
                                        <div>
                                            <h4>Court</h4>
                                            <p>punjab highcourt case</p>
                                        </div>
                                        {/* <div>
                                            <h4>Next Date Of Hearing</h4>
                                            <p>14 jan 2024</p>
                                        </div>
                                        <div>
                                            <h4>Previous Date Of Hearing</h4>
                                            <p>13 an 2023</p>
                                        </div> */}
                                        <div>
                                            <h4>Year</h4>
                                            <p>{caseData.year}</p>
                                        </div>
                                        <div className="dropdown-container">
                                            <button className="dropdown-button" onClick={toggleMenu}>
                                                    <img src={moreContentIcon} alt="More actions" />
                                            </button>
                                            {isMenuOpen && (
                                                <div className="dropdown-menu">
                                                        <button onClick={() => PunjabHighCourtCaseDetail(caseData.punjabhighcourtcase.id)} >
                                                        <span role="img" aria-label="View">👁️</span> View
                                                    </button>
                                                    {/* <button onClick={handleEdit}>
                                                        <span role="img" aria-label="Edit">✏️</span> Edit
                                                    </button> */}
                                                    <button onClick={handleDelete}>
                                                        <span role="img" aria-label="Delete">🗑️</span> Delete
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </>

    );
}

export default UserDashboard;